<template>
  <div class="client-page flex-column bg-table-header">
    <div class="p-tb-10 p-l-10 border-bottom-1 flex bg-white">
      <span class="cursor-pointer" @click="toBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </span>
      <div class="flex1 text-center font-size-16 font-weight-bold">{{ activeTitle }}</div>
    </div>
    <div class="flex1 p-a-20 flex-column overflow-auto">
      <!-- 步骤条 -->
      <div class="bg-white p-tb-20 flex flex-center" v-if="[1, 2, 3, 4, 9, 10, 11].includes(activeStep)">
        <template v-for="(item, index) in stepOptions">
          <div :key="item.id" v-if="index < 6">
            <span class="font-size-20" :class="[renderActiveStep(item)]">
              {{ item.name }}
            </span>
            <!-- <span
              class="font-size-20"
              :class="[activeStep === item.id ? 'font-size-24 font-warning font-weight-bold' : '']"
            >
              {{ item.name }}
            </span> -->
            <i v-if="index < 5" class="el-icon-arrow-right font-size-20 m-lr-20"></i>
          </div>
        </template>
      </div>
      <!-- 待付款，倒计时 -->
      <PayCountdown
        class="m-t-10"
        v-if="[9].includes(activeStep) && orderInfo.toCancelSecond"
        :toCancelSecond="!!orderInfo.toCancelSecond"
      >
        <template #text>
          <span>请尽快支付货款！</span>
        </template>
        <template #countdown>
          <Countdown :timeValue="orderInfo.toCancelSecond ? orderInfo.toCancelSecond * 1000 : 0" />
        </template>
        <template #btn>
          <el-button type="warning" round @click="toPayFn">去支付(F9)</el-button>
        </template>
      </PayCountdown>
      <!-- 开单类型 -->
      <div
        v-if="[1, 9, 2, 3, 10, 4, 5, 6, 7].includes(activeStep)"
        class="m-t-10 bg-white  p-tb-20 p-lr-20 font-size-20 font-weight-bold"
      >
        <div class="flex flex-between">
          <div class="m-b-10">
            <span>订单号：{{ orderInfo.orderCode }}</span>
          </div>
          <div>
            <span class="m-r-20">提交人：{{ orderInfo.submitByName }} </span>
            <span>提交时间：{{ orderInfo.submitAt }}</span>
          </div>
        </div>
        <div>
          <span class="m-r-20" v-show="false">开单类型：代他人开单 </span>
          <span v-if="orderInfo.buyerName">买家名称：{{ orderInfo.buyerName }}</span>
        </div>
      </div>

      <Card v-if="[11].includes(activeStep)" v-show="false">
        <template #title>开单类型</template>
        <template #content>
          <span>代他人开单</span>
          <span class="m-l-20">买家名称：王九</span>
        </template>
      </Card>
      <!-- 收货地址 -->
      <Card>
        <template #title>收货地址</template>
        <template #content>
          <div v-if="[11].includes(activeStep)">
            <EditAddress @select="selectAddress" :addressInfo="addressInfo" />
          </div>
          <div v-else>
            <span>{{ orderInfo.receiver }}</span>
            <span class="m-l-10">{{ orderInfo.receiverAreaName }}</span>
            <span class="m-l-10">{{ orderInfo.receiverAddress }}</span>
            <span class="m-l-10">{{ orderInfo.receiverPhone }}</span>
          </div>
        </template>
      </Card>

      <!-- 商品明细 -->
      <Card>
        <template #title>商品明细</template>
        <template #content>
          <OrderDetailItem
            :table="goodsDetailsTable"
            :isShow="false"
            :showOriginalPrice="[11].includes(activeStep) ? false : true"
            @change="tableDataChange"
          >
            <template #footer>
              <div>
                <div class="flex flex-row-center">
                  <span>共{{ totalCategories }}种，{{ totalQty }}件商品</span>
                  <span class="m-l-20">合计：¥{{ [11].includes(activeStep) ? totalAmount : finalAmountFn() }}</span>
                </div>
                <div class="flex flex-row-center" v-if="Number(orderInfo.concessionsAmount) > 0">
                  <span class="line-price">
                    原价：{{ Number(orderInfo.finalOriginalPrice) ? toFixedFn(orderInfo.finalOriginalPrice) : 0 }}
                  </span>
                  <span class="m-l-20">已优惠：{{ toFixedFn(orderInfo.concessionsAmount) }}</span>
                </div>
              </div>
            </template>
          </OrderDetailItem>
        </template>
      </Card>
      <!-- 订单信息 -->
      <Card>
        <template #title>订单信息</template>
        <template #content>
          <div class="bg-white orderInfo  flex">
            <div class="border-right-1 flex1" v-if="[11].includes(activeStep)">
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">结算方式：</span>
                <span class="text-666">{{ paymentType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">发票类型：</span>
                <span class="text-666">{{ invoiceType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">提货方式：</span>
                <el-select v-model="orderInfo.deliveryType" placeholder="请选择提货方式" size="small">
                  <el-option v-for="(item, index) in deliveryType" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">运输方式：</span>
                <el-select v-model="orderInfo.transportType" size="small" placeholder="请选择运输方式">
                  <el-option v-for="(item, index) in transportType" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="border-right-1 flex1" v-else>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">结算方式：</span>
                <span class="text-666">{{ orderInfo.paymentType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">发票类型：</span>
                <span class="text-666">{{ orderInfo.invoiceType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">提货方式：</span>
                <span class="text-666">{{ orderInfo.deliveryType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">运输方式：</span>
                <span class="text-666">{{ orderInfo.transportType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">发货门店：</span>
                <span class="text-666">{{ orderInfo.realSellerName }}/{{ orderInfo.realSellerPhone }}</span>
              </div>
              <div class="m-b-10" v-if="orderInfo.splitBy">
                <span class="w-100 font-weight-bold">拆单类型：</span>
                <span class="text-666">{{ orderInfo.splitBy }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">创建人：</span>
                <span class="text-666">{{ orderInfo.createdByName }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">创建时间：</span>
                <span class="text-666">{{ orderInfo.createdAt }}</span>
              </div>
            </div>
            <div class="flex1 m-l-20">
              <div class="font-weight-bold">买家备注：</div>
              <el-input
                type="textarea"
                class="m-t-4"
                placeholder="请输入你要备注的内容"
                v-model="orderInfo.remarks"
                maxlength="200"
                :disabled="![11].includes(activeStep)"
                clearable
                show-word-limit
                :autosize="{ minRows: 5, maxRows: 5 }"
              >
              </el-input>
            </div>
          </div>
        </template>
      </Card>

      <!-- 积分/优惠券抵扣 -->
      <Card v-if="[11].includes(activeStep)">
        <template #title>积分/优惠券抵扣</template>
        <template #content>
          <div>
            <div class="flex flex-row-center">
              <div class="flex flex-row-center">
                <div class="m-r-10">优惠券</div>
                <el-tooltip placement="top">
                  <div slot="content" class="white-space">{{ selectCouponInfo.description }}</div>
                  <i class="el-icon-info coupon-color cursor-pointer"></i>
                </el-tooltip>
              </div>
              <div class="flex1 flex flex-col-end">
                <span v-if="!couponList.length" class="text-999">暂无可用优惠券</span>
                <template v-else>
                  <div class="flex flex-row-center">
                    <div v-if="selectCouponInfo.id">
                      <span class="font-size-12 coupon-flag">{{ selectCouponInfo.content }}</span>
                      <span class="m-l-10 text-danger font-weight-bold">
                        {{ `-¥${Number(selectCouponInfo.amount).toFixed(2)}` }}
                      </span>
                    </div>
                    <div class="m-l-10 cursor-pointer" @click="showCouponList = !showCouponList">
                      <span>{{ showCouponList ? "收起" : "展开" }}</span>
                      <i v-if="showCouponList" class="el-icon-arrow-up"></i>
                      <i v-else class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <CouponSelect
              v-if="showCouponList && couponList.length"
              :couponList="couponList"
              :couponId="couponId"
              @couponChange="couponChange"
            />

            <div class="flex flex-row-center points p-t-10 m-tb-10 border-top-1" v-if="pointsData && pointsData.enable">
              <div class="flex1 flex flex-row-center">
                <div class="m-r-10">积分抵扣</div>
                <el-tooltip placement="top">
                  <div slot="content" class="white-space">使用积分抵扣，实际可抵扣金额后按提交时剩余可用积分计算.</div>
                  <i class="el-icon-info coupon-color cursor-pointer"></i>
                </el-tooltip>
                <div v-if="!pointsData.isDetail">（{{ pointsData.points }}分）</div>
              </div>
              <div class="flex">
                <el-checkbox v-model="usePoints" v-if="pointsData.availablePoints">
                  本次可用{{ pointsData.availablePoints }}积分抵扣{{ pointsData.availableDeductAmount }}元
                </el-checkbox>
                <span v-else class="text-999">暂无可用积分</span>
              </div>
            </div>
            <div class="flex flex-row-center p-t-10 border-top-1">
              <div class="flex1">
                <span class="font-size-12 coupon-flag">积分</span>
                <span class="m-l-10">
                  赠送
                  <span class="text-danger">{{ pointsData.incomePoints }}</span>
                  积分
                </span>
              </div>
              <div class="text-danger">订单完成后积分 +{{ pointsData.incomePoints }}</div>
            </div>
          </div>
        </template>
      </Card>

      <div class="m-t-10 bg-white p-tb-20 p-lr-20 flex flex-col-end" v-if="[11].includes(activeStep)">
        <div style="width:300px">
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-100 text-right m-r-20">总商品金额</span>
            <span class="flex1 text-right">{{ toFixedFn(totalAmount) }}</span>
          </div>
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-100 text-right m-r-20">积分抵扣</span>
            <span class="flex1 text-right" v-if="usePoints && pointsData.availablePoints">
              -{{ toFixedFn(pointsData.availableDeductAmount) }}
            </span>
            <span class="flex1 text-right" v-else>0</span>
          </div>
          <div class="flex flex-row-center">
            <span class="inline-block w-100 text-right m-r-20">优惠劵抵扣</span>
            <span class="flex1 text-right" v-if="selectCouponInfo.id">-{{ toFixedFn(selectCouponInfo.amount) }}</span>
            <span class="flex1 text-right" v-else>0</span>
          </div>
        </div>
      </div>
      <div class="m-t-10 bg-white p-tb-20 p-lr-20 flex flex-col-end" v-else>
        <div style="width:300px">
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-100 text-right m-r-20">商品总价</span>
            <span class="flex1 text-right">{{ toFixedFn2(totalAmount) }}</span>
          </div>
          <div v-if="couponInfo && couponInfo.id" class="flex flex-row-center m-b-10">
            <span class="inline-block w-100 text-right m-r-20">优惠券</span>
            <span class="flex1 text-right">
              <span class="font-size-12 coupon-flag">{{ couponInfo.content }}</span>
              <span class="m-l-10 text-danger font-weight-bold">
                {{ `-¥${Number(couponInfo.amount).toFixed(2)}` }}
              </span>
            </span>
          </div>
          <div v-if="pointsData && pointsData.enable" class="flex flex-row-center m-b-10">
            <span class="inline-block w-100 text-right m-r-20">积分抵扣</span>
            <span class="flex1 text-right text-danger" v-if="pointsData.availablePoints">
              本次使用{{ pointsData.availablePoints }}积分抵扣{{ pointsData.availableDeductAmount }}元
            </span>
            <span v-else class="flex1 text-right text-999">暂无可用积分</span>
          </div>
          <div v-if="pointsData && pointsData.enable && pointsData.incomePoints" class="flex flex-row-center m-b-10">
            <span class="inline-block w-100 text-right m-r-20">
              <span class="font-size-12 coupon-flag">积分</span>
            </span>
            <span class="flex1 text-right text-danger">订单完成后积分 +{{ pointsData.incomePoints }}</span>
          </div>
          <template v-if="[9].includes(activeStep)">
            <div class="flex flex-row-center m-b-10">
              <span class="inline-block w-100 text-right m-r-20">实付金额</span>
              <span class="flex1 text-right">{{ toFixedFn(orderInfo.paidAmount) }}</span>
            </div>
            <div class="flex flex-row-center">
              <span class="inline-block w-100 text-right m-r-20">应付金额</span>
              <span class="flex1 text-right font-size-20 font-weight-bold text-danger">
                {{ toFixedFn(orderInfo.payAmount) }}
              </span>
            </div>
          </template>
          <template v-else-if="![11, 9].includes(activeStep)">
            <div class="flex flex-row-center m-b-10">
              <span class="inline-block w-100 text-right m-r-20">应付金额</span>
              <span class="flex1 text-right">{{ toFixedFn(orderInfo.payAmount) }}</span>
            </div>
            <div class="flex flex-row-center">
              <span class="inline-block w-100 text-right m-r-20">实付金额</span>
              <span class="flex1 text-right font-size-20 font-weight-bold text-danger">
                {{ toFixedFn(orderInfo.paidAmount) }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="flex flex-between flex-row-center bg-white p-tb-10 p-lr-10  border-top-1"
      v-if="[11].includes(activeStep)"
    >
      <div>
        <!-- 暂时隐藏 -->
        <template v-if="!isBindErp && false">
          <span class="font-weight-bold m-r-20">
            绑定思锐账ERP号，实现ERP与订货系统订单互联，节省ERP录入采购单时间
          </span>
          <el-button type="text" size="small" @click="toBindErp">去绑定</el-button>
        </template>
      </div>
      <div class="flex flex-row-center">
        <div>
          <div>
            <span class="font-weight-bold m-r-20"> 共{{ totalCategories }}种，{{ totalQty }}件商品 </span>
            <span class="font-weight-bold m-r-20 text-danger"> 总价：￥{{ Number(amount.total).toFixed(2) }} </span>
          </div>
          <div>
            <span class="line-price">原价：{{ toFixedFn(amount.originalPrice) }}</span>
            <span class="text-danger m-l-10">
              已优惠：{{ Number(amount.deductAmount) > 0 ? toFixedFn(amount.deductAmount) : 0 }}
            </span>
          </div>
        </div>
        <el-button v-loading="subLoading" type="warning" size="small" round @click="submitOrder">
          提交订单(F2)
        </el-button>
      </div>
    </div>
    <div class="bg-white p-tb-10 p-lr-10 text-right border-top-1" v-else>
      <el-button
        type="primary"
        size="small"
        v-if="[1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(activeStep)"
        @click="orderLogFn"
      >
        订单日志(Alt+L)
      </el-button>
      <el-button
        type="primary"
        size="small"
        v-if="[1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(activeStep)"
        @click="exportFn"
      >
        导出订单(O/Alt+O)
      </el-button>
      <el-button type="primary" size="small" v-if="[5, 6, 7].includes(activeStep)" @click="deleteFn">
        删除订单(F3)
      </el-button>
      <el-button type="primary" size="small" v-if="[1, 9].includes(activeStep)" @click="cancelFn">
        取消订单(ESC)
      </el-button>
      <el-button type="primary" size="small" v-if="[1, 2, 11].includes(activeStep)" @click="updateFn">
        修改订单(F12)
      </el-button>
      <el-button type="primary" size="small" v-if="[9].includes(activeStep)" @click="toPayFn">
        去付款(F9)
      </el-button>
      <el-button
        type="primary"
        size="small"
        v-if="[9].includes(activeStep) && ['待付款-待卖家审核', '待付款-审核不通过'].includes(activeStatusName)"
        @click="payInfoFn"
      >
        支付信息
      </el-button>
      <!-- <el-button type="primary" size="small" v-if="[9].includes(activeStep)" @click="payInfoFn">
        支付信息
      </el-button> -->
      <el-button type="primary" size="small" v-if="[2, 4].includes(activeStep)" @click="buyAgainFn">
        再次购买(Alt+F)
      </el-button>
      <el-button type="primary" size="small" v-if="[3].includes(activeStep)" @click="logisticInfoFn">
        物流信息(Alt+A)
      </el-button>
      <el-button type="primary" size="small" v-if="[3].includes(activeStep)" @click="confirmReceiptFn">
        确认收货(F10)
      </el-button>
      <!-- 一期不做 -->
      <!-- <el-button type="primary" size="small" v-if="[10].includes(activeStep)" @click="toAppointFn">
        去预约(Alt+B)
      </el-button>
      <el-button type="primary" size="small" v-if="[4, 10].includes(activeStep)" @click="applyServiceFn">
        申请售后(Alt+C)
      </el-button> -->
    </div>
    <!-- 思锐账号绑定弹框 -->
    <BindErpBox :visible.sync="bindErpVisible" />
    <!-- 订单日志弹框 -->
    <OrderLogBox :visible.sync="orderLogVisible" :curRow="orderInfo" />
    <!-- 物流信息弹框 -->
    <LogisticInfoBox :visible.sync="logisticInfoVisible" :curRow="orderInfo" />
    <!-- 确认收货弹框 -->
    <ConfirmReceiptBox :visible.sync="confirmReceiptVisible" :curRow="orderInfo" @refresh="refreshFn" />
    <!-- 在线预约/修改预约弹框 -->
    <AppointBox :visible.sync="appointVisible" :curRow="orderInfo" />
    <!-- 下单弹框 -->
    <PlaceOrderType ref="placeOrderType" />
    <!-- 支付信息弹框 -->
    <PayInfoBox :visible.sync="payInfoVisible" :curRow="orderInfo" />
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import EditAddress from "../components/EditAddress.vue";
import OrderDetailItem from "../components/OrderDetailItem.vue";
import BindErpBox from "./components/BindErpBox.vue";
import OrderLogBox from "../components/OrderLogBox.vue";
import PayInfoBox from "../components/PayInfoBox.vue";
import LogisticInfoBox from "../components/LogisticInfoBox.vue";
import ConfirmReceiptBox from "../components/ConfirmReceiptBox.vue";
import AppointBox from "../components/AppointBox.vue";
import PayCountdown from "../components/PayCountdown.vue";
import Countdown from "../components/Countdown.vue";
import PlaceOrderType from "@/components/PlaceOrderType";
import CouponSelect from "@/components/Coupon/CouponSelect.vue";

import {
  getBeforeCreateOrderParams,
  createOrder,
  getOrderDetail,
  updateOrders,
  postOrdersExport,
  postOrdersDelete
} from "@/api/order";
import { getPointsDeduct } from "@/api/points";
// import { getCustomerCouponDetail } from "@/api/coupon";

export default {
  name: "orderDetail",
  components: {
    Card,
    EditAddress,
    OrderDetailItem,
    BindErpBox,
    OrderLogBox,
    PayInfoBox,
    LogisticInfoBox,
    ConfirmReceiptBox,
    AppointBox,
    PayCountdown,
    Countdown,
    CouponSelect,
    PlaceOrderType
  },
  data() {
    return {
      keyName: "*",
      keyScope: "OrderDetails",
      // 当前状态
      activeStep: 11,
      // 步骤
      stepOptions: [
        { id: 11, name: "提交订单" },
        { id: 1, name: "待确认" },
        { id: 9, name: "待付款" },
        { id: 2, name: "待发货" },
        { id: 3, name: "待收货" },
        // { id: 10, name: "待服务" },
        { id: 4, name: "已完成" },
        { id: 6, name: "卖家取消" },
        { id: 5, name: "买家取消" },
        { id: 7, name: "系统取消" }
      ],
      // 提货方式
      deliveryType: [],
      // 运输方式
      transportType: [],
      // 订单数据
      orderInfo: {},
      addressInfo: {},
      //是否已绑定erp
      isBindErp: false,
      // 弹窗
      bindErpVisible: false,
      orderLogVisible: false,
      logisticInfoVisible: false,
      confirmReceiptVisible: false,
      appointVisible: false,
      // 路由参数
      query: {},
      // 其他页面下订单传入到此页面的数据
      info: {},
      // 商品明细表
      goodsDetailsTable: [],
      // 提交订单前获取的参数
      beforeCreateOrderParams: {},
      // 提交订单前获取的提交参数
      getBeforeCreateOrderParamsData: {},
      // 提交状态
      subLoading: false,
      // 支付信息
      payInfoVisible: false,

      // 优惠券，积分
      couponList: [],
      showCouponList: true,
      couponId: 0,
      couponInfo: {
        id: 0,
        content: "",
        amount: "",
        description: ""
      },

      pointsData: {
        availableDeductAmount: 0,
        availablePoints: 0,
        points: 0,
        incomePoints: 0,
        enable: true,
        isDetail: false
      },
      usePoints: true, // 是否使用积分
      count: 0
    };
  },
  computed: {
    activeTitle() {
      return this.stepOptions.find(item => item.id === this.activeStep).name;
    },
    // 当前状态名称
    activeStatusName() {
      return this.orderInfo.statusName;
    },
    // 总种类数
    totalCategories() {
      if (this.query.info) {
        return this.beforeCreateOrderParams.totalCategories;
      } else {
        return this.goodsDetailsTable.length;
      }
    },
    // 总件数
    totalQty() {
      if (this.query.info) {
        return this.beforeCreateOrderParams.totalQty;
      } else {
        return this.orderInfo.totalQty;
      }
    },
    // 总金额
    totalAmount() {
      let totalAmount = "";
      if (this.query.info) {
        totalAmount = Number(this.beforeCreateOrderParams.totalAmount).toFixed(2);
      } else {
        totalAmount = Number(this.orderInfo.totalAmount).toFixed(2);
      }
      return isNaN(totalAmount) ? "议价" : totalAmount;
    },
    // 选中的优惠券(activeStep:11 待提交)
    selectCouponInfo() {
      return (
        this.couponList.find(item => item.id === this.couponId) || {
          id: 0,
          content: "",
          amount: "",
          description: ""
        }
      );
    },
    // 合计金额(activeStep:11 待提交)
    amount() {
      const data = {
        total: "0", // 合计金额
        originalPrice: "0", // 原价
        deductAmount: this.selectCouponInfo.amount || "0" // 已优惠金额
      };

      const totalFee = this.beforeCreateOrderParams.totalAmount || "0";
      let totalOriginalPrice = 0.0; // 总原价，通过originalPrice计算
      let promotionDeductAmount = 0.0; // 促销优惠

      this.beforeCreateOrderParams.rows?.forEach(cart => {
        totalOriginalPrice += parseFloat(cart.goods.originalPrice) * parseInt(cart.qty);
        promotionDeductAmount +=
          (parseFloat(cart.goods.originalPrice) - parseFloat(cart.goods.price)) * parseInt(cart.qty);
        // if (cart.checkedInstall) {
        //   totalFee += parseFloat(cart.installCost) * parseInt(cart.qty);
        //   totalOriginalPrice += parseFloat(cart.installCost) * parseInt(cart.qty);
        // }
      });

      if (this.selectCouponInfo?.id) {
        data.total = (Number(totalFee) - parseFloat(this.selectCouponInfo?.amount)).toString();
      } else {
        data.total = totalFee + "";
      }

      if (this.usePoints) {
        data.total = (Number(data.total) - this.pointsData.availableDeductAmount).toString();
        data.deductAmount = (Number(data.deductAmount) + this.pointsData.availableDeductAmount).toString();
      }
      if (promotionDeductAmount > 0) {
        data.deductAmount = (Number(data.deductAmount) + Number(promotionDeductAmount)).toFixed(2);
      }
      data.originalPrice = totalOriginalPrice.toFixed(2);
      // console.log("data", data);

      return data;
    },
    // 结算方式
    paymentType() {
      return this.beforeCreateOrderParams.paymentType;
    },
    // 发票类型
    invoiceType() {
      return this.beforeCreateOrderParams.invoiceType;
    }
  },
  watch: {
    couponId: {
      handler() {
        this.refreshPointsData();
      }
    },
    activeStep: {
      handler(val) {
        if (val === 1 && this.count <= 6) {
          setTimeout(() => {
            this.reqLoopGetOrderDetail();
          }, 1000);
        }
      },
      deep: true
    }
  },
  created() {
    this.query = this.$route.query;
    this.setInfo();
    if (this.$route.query.id) {
      this.getOrderDetailFn();
    }
  },
  mounted() {
    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    // 当前状态为待确认时，每隔1秒发起请求 6次 过后停止请求
    finalAmountFn() {
      return Number(this.orderInfo.finalOriginalPrice) > 0
        ? (Number(this.orderInfo.finalOriginalPrice) - Number(this.orderInfo.concessionsAmount)).toFixed(2)
        : 0;
    },
    // 重新获取积分数据
    refreshPointsData() {
      const amount1 = this.usePoints
        ? Number(this.amount.total || 0) + Number(this.pointsData.availableDeductAmount || 0)
        : this.amount.total;
      getPointsDeduct({ amount: amount1 })
        .then(res => {
          this.pointsData = {
            availableDeductAmount: Number(res.availableDeductAmount || 0),
            availablePoints: Number(res.availablePoints || 0),
            points: Number(res.points || 0),
            incomePoints: Number(res.incomePoints || 0),
            enable: res.enable,
            isDetail: false
          };
        })
        .catch(err => {
          this.$message.error(err?.message || "加载失败");
        });
    },
    // 选择优惠券
    couponChange(id) {
      this.couponId = id;
    },
    // 是否使用积分
    usePointsFlagChangeFn(isUsed) {
      this.usePoints = isUsed;
    },
    // 待确认时，每隔1秒发起请求 6次 过后停止请求
    reqLoopGetOrderDetail() {
      this.getOrderDetailFn();
      this.count++;
      if (this.activeStep == 1 && this.count < 6) {
        setTimeout(() => {
          this.reqLoopGetOrderDetail();
        }, 1000);
      }
    },
    handleKeyCode(e) {
      switch (e.code) {
        // 提交订单
        case "F2":
          e.preventDefault();
          e.stopPropagation();
          if ([11].includes(this.activeStep) && !this.subLoading) {
            this.submitOrder();
          }
          break;
        // 删除订单
        case "F3":
          e.preventDefault();
          e.stopPropagation();
          if ([5, 6, 7].includes(this.activeStep)) {
            this.deleteFn();
          }
          break;
        // 去支付
        case "F9":
          e.preventDefault();
          e.stopPropagation();
          if ([9].includes(this.activeStep) && this.orderInfo.toCancelSecond) {
            this.toPayFn();
          }
          break;
        // 确认收货
        case "F10":
          e.preventDefault();
          e.stopPropagation();
          if ([3].includes(this.activeStep)) {
            this.confirmReceiptFn();
          }
          break;
        // 修改订单
        case "F12":
          e.preventDefault();
          e.stopPropagation();
          if ([1, 2, 11].includes(this.activeStep)) {
            this.updateFn();
          }
          break;
        // 订单日志
        case "KeyL":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey && [1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(this.activeStep)) {
            this.orderLogFn();
          }
          break;
        // 导出订单
        case "KeyO":
          e.preventDefault();
          e.stopPropagation();
          if ([1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(this.activeStep)) {
            this.exportFn();
          }
          break;
        // 取消订单
        case "Escape":
          e.preventDefault();
          e.stopPropagation();
          if ([1, 9].includes(this.activeStep)) {
            this.cancelFn();
          }
          break;
        // 再次购买
        case "KeyF":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey && [2, 4].includes(this.activeStep)) {
            this.buyAgainFn();
          }
          break;
        // 物流信息
        case "KeyA":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey && [3].includes(this.activeStep)) {
            this.logisticInfoFn();
          }
          break;
        // 去预约
        case "KeyB":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey && [10].includes(this.activeStep)) {
            this.toAppointFn();
          }
          break;
        // 申请售后
        case "KeyC":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey && [4, 10].includes(this.activeStep)) {
            this.applyServiceFn();
          }
          break;
      }
    },
    renderActiveStep(item) {
      if (this.activeStep === item.id) {
        return "font-size-24 font-warning font-weight-bold";
      } else if ([4, 5, 6, 7].includes(this.activeStep) && item.id === 4) {
        return "font-size-24 font-warning font-weight-bold";
      }
    },
    //金额保留两位小数
    toFixedFn(amount) {
      if (amount === "0" || Number(amount) === 0) {
        return "- -";
      }
      return isNaN(Number(amount)) ? "议价" : `￥${Number(amount).toFixed(2)}`;
    },
    toFixedFn2(amount) {
      if (amount === "0" || Number(amount) === 0) {
        return "￥0";
      }
      return isNaN(Number(amount)) ? "议价" : `￥${Number(amount).toFixed(2)}`;
    },
    // 设置从下单页面进入此页面传入的参数
    setInfo() {
      if (this.query && this.query.info) {
        this.info = JSON.parse(decodeURIComponent(this.query.info));
        this.getBeforeCreateOrderParams();
      }
    },
    // 获取创建订单前的参数
    getBeforeCreateOrderParams() {
      let type = 0;
      let carts = [];
      let skus = [];
      // 商品详情页下单
      if (this.info.type === "productsDetails") {
        type = 3;
        skus = [this.info.data];
      }
      // 购物车页下单
      if (this.info.type === "shoppingCart") {
        type = 1;
        carts = this.info.data;
      }
      // 订单再次购买
      if (this.info.type === "orderList") {
        type = 3;
        skus = this.info.data;
      }
      const data = {
        type: type, // 获取方式 1.购物车自己采购下单 2.购物车代他人开单 3.查询页直接下单 4.代理客户获取确认订单参数 5.待卖家审核状态下，买家修改订单明细中的订货数量，获取修改参数
        carts: carts, // 购物车信息
        skus: skus, // 查询商品信息
        dict: true // 是否需要字典信息
      };
      this.getBeforeCreateOrderParamsData = data;
      getBeforeCreateOrderParams(data)
        .then(res => {
          this.beforeCreateOrderParams = res || {};
          this.deliveryType = res.deliveryType || [];
          this.transportType = res.transportType || [];
          this.addressInfo = {
            type: "orderDetail"
          };

          this.setGoodsDetailsTable();

          this.pointsData = {
            availableDeductAmount: Number(res.availableDeductAmount || 0),
            availablePoints: Number(res.availablePoints || 0),
            points: Number(res.points || 0),
            incomePoints: Number(res.incomePoints || 0),
            enable: res.enable,
            isDetail: false
          };

          this.couponList = res.coupons || [];
          if (res.coupons?.length) {
            this.couponId = res.coupons[0].id;
          }
        })
        .catch(err => {
          this.$message.error(err.message || "参数获取失败");
        });
    },
    setGoodsDetailsTable() {
      const list = [];
      this.beforeCreateOrderParams.rows.map(item => {
        list.push({
          id: item.goods.id,
          type: item.goods.type,
          code: item.goods.code,
          name: item.goods.name,
          brand: item.goods.brand,
          productionPlace: item.goods.productionPlace,
          originalPrice: item.goods.originalPrice,
          price: item.goods.price,
          qty: item.qty,
          amount: item.amount,
          warehouse: item.warehouse,
          productBatch: item.productBatch,
          category: item.goods.category,
          vehModel: item.goods.vehModel,
          property: item.goods.property,
          unitName: item.goods.unit, //单位
          insurCertType: item.goods.insurCertType, //品质
          model: item.goods.model,
          productionCode: item.goods.productionCode,
          goodsSpecification: item.goodsSpecification, //购买规格参数
          promotion: item.goods.promotion, //促销
          coupons: item.goods.coupons //优惠券
        });
      });
      this.goodsDetailsTable = list;
    },
    getOrderDetailFn() {
      getOrderDetail({ id: this.query.id })
        .then(res => {
          this.orderInfo = res || {};
          this.activeStep = res.status;
          this.goodsDetailsTable =
            res.details.map(item => ({
              ...item,
              amount: (Number(item.qty) * Number(item.price)).toFixed(2)
            })) || [];
          if (res.concessions && res.concessions.coupon) {
            this.couponInfo = res.concessions.coupon;
          }
          this.pointsData = {
            availableDeductAmount: Number(res.concessions?.point?.amount || 0),
            availablePoints: Number(res.concessions?.point?.points || 0),
            points: Number(res.points || 0),
            incomePoints: Number(res.incomePoints || 0),
            enable: res.enable,
            isDetail: true
          };
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    //刷新列表
    refreshFn() {
      this.getOrderDetailFn();
    },

    //商品明细
    tableDataChange(rows) {
      this.orderInfo.details = JSON.parse(JSON.stringify(rows));
    },
    //返回
    toBack() {
      this.$router.go(-1);
    },
    //选择地址
    selectAddress(data) {
      this.orderInfo.addressId = data.id;
    },
    //去绑定erp
    toBindErp() {
      this.bindErpVisible = true;
    },
    //返回订货列表并刷新
    replaceOrderList() {
      this.$router.replace({
        path: "/order/order-list",
        query: {
          type: "refresh"
        }
      });
    },
    //提交订单
    submitOrder: function() {
      if (!this.orderInfo.addressId) {
        this.$message.warning("请选择收货地址或者创建！");
        return;
      }
      // const prices = this.goodsDetailsTable.map(item => item.price);
      const data = {
        addressId: this.orderInfo.addressId, // 地址ID
        carts: this.getBeforeCreateOrderParamsData.carts, // 购物车
        skus: this.getBeforeCreateOrderParamsData.skus, // 查询商品
        type: 0, // 订单类型 0: 普通订单 1: 急件订单 2: 补货订单 3: 批量订单 4: 现货订单 5: 期货订单
        buyerConfirm: false, // 是否需要买家确认或付款 true/false
        paymentType: this.beforeCreateOrderParams.paymentType, // 结算方式
        invoiceType: this.beforeCreateOrderParams.invoiceType, // 发票类型
        deliveryType: this.orderInfo.deliveryType, // 提货方式
        transportType: this.orderInfo.transportType, // 运输方式
        packType: "", // 包装方式
        remarks: this.orderInfo.remarks, // 备注
        priceLevels: [],
        // prices: prices, // 商品当前价格，用于下单时检查价格是否变动
        couponId: this.couponId, // 使用的已领优惠券ID
        consumePoints: this.usePoints ? this.pointsData.availablePoints : 0, // 消耗积分
        toStoreService: false, // 是否到店服务
        CustomerOrderCode: "",
        stockoutOperation: 0 // 缺货处理，orderType=5（缺货商品）时，该参数有效。1:缺货配件取消；2:缺货配件转期货订单；3:缺货配件转预约
      };
      this.subLoading = true;
      createOrder(data)
        .then(res => {
          this.subLoading = false;
          // 跳转待确认
          if (res && res.length) {
            this.$message.success("提交成功");
            if (res.length > 1) {
              const message = `<div class="text-center">由于库存或其他原因<br/>您的订单已拆分为多张订单您将收到不同的发货通知。</div>`;
              const option = { dangerouslyUseHTMLString: true };
              this.$alert(message, "提示", option)
                .then(() => {
                  this.replaceOrderList();
                })
                .catch(() => {
                  this.replaceOrderList();
                });
            } else {
              this.$router.replace({
                path: "/order/public",
                query: {
                  id: res[0].id,
                  url: "/order/order-list/order-detail"
                }
              });
            }
          } else {
            this.$message.error("提交订单失败！");
          }
        })
        .catch(err => {
          this.subLoading = false;
          this.$message.error(err.message || "提交订单失败！");
        });
    },
    //订单日志
    orderLogFn() {
      this.orderLogVisible = true;
    },
    // 支付信息
    payInfoFn() {
      this.payInfoVisible = true;
    },
    //取消订单
    cancelFn() {
      this.$confirm(`是否确认取消此订单？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            id: this.orderInfo.id,
            status: 5 //5 : 买家取消
          };
          updateOrders(params)
            .then(() => {
              this.$message.success("取消成功");
              this.replaceOrderList();
            })
            .catch(() => {
              this.$message.error("取消失败");
            });
        })
        .catch(() => {});
    },
    //删除订单
    deleteFn() {
      this.$confirm(`是否确认删除此订单？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            ids: [this.orderInfo.id]
          };
          postOrdersDelete(params)
            .then(() => {
              this.$message.success("删除成功");
              this.replaceOrderList();
            })
            .catch(err => {
              this.$message.error(err?.message || "删除失败");
            });
        })
        .catch(() => {});
    },
    //导出订单
    exportFn() {
      this.$confirm(`是否确认导出此订单？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            ids: this.orderInfo.id + "",
            exportType: "orderDetail" // 导出类型 order: 订单 orderDetail: 订单明细
          };
          this.reqOrdersExport(params);
        })
        .catch(() => {});
    },
    reqOrdersExport(params) {
      postOrdersExport(params)
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          const name = `订单列表_${this.$dayjs().format("YYYY-MM-DD")}.xls`;
          link.setAttribute("download", name);
          link.click();
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err?.message || "导出失败");
        });
    },
    //修改订单
    updateFn() {
      let param = {};
      if ([1, 2].includes(this.activeStep)) {
        //待确认、待发货
        param = {
          path: "/order/order-list/modify-order-detail1",
          query: {
            id: this.orderInfo.id
          }
        };
      }
      this.$router.push(param);
    },
    //去付款
    toPayFn() {
      const obj = {
        ids: this.orderInfo.id + ""
      };
      const param = {
        path: "/order/order-list/order-pay",
        query: {
          data: encodeURIComponent(JSON.stringify(obj))
        }
      };
      this.$router.push(param);
    },
    //再次购买
    buyAgainFn() {
      const data = window.tool.deepClone(this.orderInfo);
      this.$refs.placeOrderType.show({
        type: "orderList",
        data: data
      });
    },
    //物流信息
    logisticInfoFn() {
      this.logisticInfoVisible = true;
    },
    //确认收货
    confirmReceiptFn() {
      this.confirmReceiptVisible = true;
    },
    //收货确认
    // sureReceiptFn(data) {
    //   console.log("收货确认", data);
    // },
    //去预约
    toAppointFn() {
      this.appointVisible = true;
    },
    //申请售后
    applyServiceFn() {
      const param = {
        path: "/order/after-sale",
        query: {
          id: this.orderInfo.id
        }
      };
      this.$router.push(param);
    }
  }
};
</script>

<style lang="less" scoped>
.flag {
  background: #f56c6c;
  color: #ffffff;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 12px;
}
.address {
  /deep/.el-button--small {
    padding: 8px 0;
  }
  .transform-90 {
    transform: rotate(90deg);
  }
  .noData {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }
}
.orderInfo {
  span {
    display: inline-block;
  }
}
.coupon-color {
  color: #fe7448;
}
.coupon-flag {
  color: #f56c6c;
  padding: 0 4px;
  border-radius: 2px;
  background: rgba(255, 110, 76, 0.08);
  border: 1px solid #e84f47;
}
.points {
  /deep/.el-checkbox {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    .el-checkbox__label {
      padding-right: 10px;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #f56c6c;
    }
    // .el-checkbox__input.is-checked .el-checkbox__inner {
    //   background: #f56c6c;
    //   border-color ##f56c6c;
    // }
  }
}
</style>
